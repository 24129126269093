import React from 'react'
import Link from 'gatsby-link'
import Card from '../components/Card';
import Testimonial from '../components/Testimonial';
import Section from '../components/Section';
import Sectionnew from '../components/Sectionnew';

import Wave from '../components/Wave';
import staticdata from '../../staticdata.json'
import Cell from '../components/Cell';
import styled from 'styled-components'
import NavBar from '../components/Navbar/Navbar'
import '../components/contact.css'
import Footer from '../components/footer'
import ReactDOM from 'react-dom'
import App from '../components/App'
import Navbar from '../components/Navbar/Navbar'
import Tabs from '../components/Tabs';
import DarkModeSwitch from '../components/switch.js'
import '../components/dms.scss'
import {mode, setMode} from '../components/switch.js'
import Head from '../components/head'
import { Helmet } from 'react-helmet'
import { JsonLd } from '../components/JsonLd'
import './menu.css'


const Menuwrapper =styled.div`
 
 width:100%
    margin:0 auto;
  
  
  
`


const Menuitems =styled.div`
  display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    
    justify-items: center;
  max-width:1600px;
    margin:0px auto;
  
  
  @media (max-width: 640px) {
      grid-template-columns: repeat(1, 1fr);
      margin:20px 0px 0px 0px;
      padding:10px;
    }
`

const SectionCaption = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #94A4BA;
  text-align: center;
`

const Button = styled.button`
    background: linear-gradient(102.24deg, #ffdd55 0%, #ffdd55 100%);
    box-shadow: 0px 10px 20px rgba(101, 41, 255, 0.15);
    border-radius: 30px;
    color: #333333;
    border: none;
    padding: 16px 60px;
    font-weight: 600;
    font-size: 16px;
    justify-self: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
        box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
        transform: translateY(-3px);
       
    }
`
const FeaturesButton = styled.button`
padding: 8px 20px;
font-size: 16px;
background: linear-gradient(102.24deg, #9B51E0 0%, #3436E7 100%);
border: none;
font-weight: 700;
letter-spacing:0px;
border-radius: 10px;
outline: none;
cursor: pointer;
transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
color:#f1f1f1;
margin: 0px 20px;
margin-top:50px;
}
`

const SectionCellGroup = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  padding: 0 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`





const MenuPage = () => (

  
  <div>
    

  
   
  <Head title="Bespoke Catering Menu"/>
  
  <JsonLd>
          {{
            '@context': 'https://schema.org',
            '@type': 'Organization',
            '@id': 'https://www.timasflavours.com/#organization',
            url: 'https://www.timasflavours.com',
            name: 'Timas Flavours',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '0208 838 0073',
              contactType: 'Customer service',
            },
            image: {
              '@type': 'ImageObject',
              url: 'https://www.websitesbymo.com/images/wbm-homepage.jpg',   
          },
          logo: {
            '@type': 'ImageObject',
            url: 'https://www.websitesbymo.com/images/logo-wbm.svg',   
            width: '200',
        },
        'name': 'websitesbymo',
        'sameAs': [
            'https://twitter.com/websitesbymo',
            'https://www.facebook.com/websitesbymo',
            'https://instagram.com/websitesbymo',
            'https://www.youtube.com/channel/UCtLjW-3UieScgO-wscZzAjA',
           'https://www.linkedin.com/company/websitesbymo',
        ],
          }}
        </JsonLd>

<Navbar />

    <div className="containerflex">
  
      <div className="item">
        <p><span className="lighttext">Tima's</span> Catering Menu.

</p>
      </div>

      

    
      <div className="item">
      <img  className="itemimg"  src='/images/menutima.png'   />

      </div>

        <Wave />
     
    </div>
   

  

   

    

<Menuwrapper>
    <Menuitems>
         
    <div className="canapes">
    <img  src='/images/catering-tima.svg' />
        <h4>Savoury Canapes</h4>
    <p>Goat cheese and tomato muffin / £1 (1 piece)</p>
    <p>Salmon and avocado Blini / £1 (1 serving)
</p>
<p>Mini cheeseburgers/ Mini hot dog/ Mini pizza / £1 (2 pieces)
</p>
<p>Moroccan Lamb skewer / £1 (1 serving)
</p>
<p>Homemade Meat/Veggie Mince pie / £1 (1 piece)
</p>
<p>Spicy Prawn noodles / £2 (1 serving)
</p>
<p>Beef wellington / £10 (serves 6 people)
</p>
    </div>
    <div className="dishes">
    <img  src='/images/catering-01.svg' />
        <h4>Savoury Dishes</h4>
    <p>Chicken wings / £2 (3 pieces)
</p>
<p>Chicken drumsticks / £2 (2 pieces)
</p>
<p>Tima’s Plantain / £1 (1 serving)
</p>
<p>Herbs potatoes / £1 (1 serving)</p>
<p>Zoodles / £0.90 (1 serving)</p>
<p>Frenchie Mac & Cheese / £0.90 (1 serving)</p>
<p>Vegetable fried rice / £1 (1 serving)</p>
<p>Joloff rice / £1 (1 serving)</p>
<p>Peanut butter stew / £2 (1 serving)</p>
    </div>



    <div className="desserts">
    <img  src='/images/deserts.svg' />
        <h4>Desserts</h4>
  <p>Cheesecake / £1 (2 pieces)</p>
  <p>Pop cake / £1.50 (2 pieces)</p>
  <p>Fruit muffin / £0.90 (1 piece)</p>
  <p>Chocolate mousse / £0.90 (1 piece)
</p>
<p>Nutty muffin / £0.90 (1 piece)</p>
   <p>Puff Puff or original doughnut / £1 (2 pieces)</p> 
   <p>Pancakes/ Crepes / £1 (3 pieces)</p>
   <p>Tima’s French Madeleines / £1 (1 piece)
</p>
   <p>Pistachio Financiers / £1 (1 piece)
</p>
   </div>



   <div className="drinks">
    <img  src='/images/drinks.svg' />
        <h4>Drinks</h4>
 <p>Hibiscus Juice / £1</p>
 <p>Ginger Cocktails / £1</p>
   </div>
         

         
          </Menuitems>

          </Menuwrapper>


          <Section
      image='/images/newbg.png'
      logo=''
      title="Interested?"
      text="Want Tima's Flavours to handle your professional catering services? Please provide as much details as you can on our contact form and we’ll get back to you within 48 hours"
      button="Get In Touch"
     />
      

      <div className="Cards">
      <h4>CUSTOMER STORIES</h4>
      <h2>5 STARS ON GOOGLE</h2></div>

<div className="Testimonial">
      
     
<Testimonial 
          title="We Hired Tima for our corporate event and the food was absolutely amazing as was the service! Highly recommended!"
          text=""
          author="Joshua"
          image='/images/josh.jpg' /> 
         
        <Testimonial 
          title="I absolutely recommend Tima to anyone who has an event that requires catering. I send my children to her cooking classes too!"
          text=""
          author="Elizabeth"
          image='/images/eli.jpg' />
             
    </div>
    <div className="Cardb">
    <Button>More Stories</Button>
</div>
    
     <Footer />
     

  </div>
)

export default MenuPage
