import React from 'react';
import Wave from '../../components/Wave';
import styled from 'styled-components'

// Components
import HeroSlider, {
  Slide,
  SideNav,
  OverlayContainer,
} from 'hero-slider'
import StyledSlide from './StyledSlide';
import Wrapper from '../UI/Wrapper/Wrapper';

import Title from '../UI/Title/Title';
import Subtitle from '../UI/Subtitle/Subtitle';

const WaveBottom = styled.div`
    position: absolute;
    width: 100%;
    bottom: -40px;
`

const WaveTop = styled.div`
    position: absolute;
    width: 100%;
    
    top: -40px;
    border:none;
    transform: rotate(180deg);
`

const app = () => {
 

  return (
    
    <HeroSlider
    
      slidingAnimation='left_to_right'
      orientation='horizontal'
      initialSlide={1}
      // onBeforeChange={(previousSlide, nextSlide) => console.log('onBeforeChange', previousSlide, nextSlide)}
      onChange={(nextSlide) => console.log('onChange', nextSlide)}
      // onAfterChange={(nextSlide) => console.log('onAfterChange', nextSlide)}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0)'
      }}
      settings={{
        slidingDuration: 0,
        slidingDelay: 0,
        shouldAutoplay: false,
        shouldDisplayButtons: true,
        shouldSlideOnArrowKeypress: true,
        autoplayDuration: 5000,
        height: '120vh'
       
      }}>
      <OverlayContainer>
      <SideNav  >
     
        </SideNav> 
      <WaveTop><Wave /></WaveTop>
  
        <Wrapper>
       
        </Wrapper>
        
        <WaveBottom><Wave /></WaveBottom>
      </OverlayContainer>

      {(() => {
        console.log('HOC slide')
        return (
          <StyledSlide
          navDescription='Giau Pass - Italy'
          
          >  <Wrapper>
                <div className="Cards">
      <h4>ALL MY WEB DESIGN SOLUTIONS</h4>
      <h2>ARE PACKED WITH FEATURES</h2>

    </div>
            <img alt="" src='/images/speed-optimized.svg' width="70" />
          <Title>
          
           Speed Optimized
          </Title>
          <Subtitle>
          

I follow a certified checklist provided by GT Metrix to ensure that your website performs at 100% speed optimisation so you never lose a sale or customer even on the most busy festive periods.

<br></br><br></br>
Remember, Google penalises websites that are slow and unfortunately too many web design firms miss the mark on providing a personalised approach to ensure your website is optimised on speed and delivery.
          </Subtitle>
        </Wrapper></StyledSlide>
        )
      })()}

      <StyledSlide
        navDescription='Bogliasco - Italy'> <Wrapper>
          <img alt="" src='/images/tailored-design.svg' width="70" />
        <Title>
        
         Tailored Design
        </Title>
        <Subtitle>
        All my websites are carefully designed to offer the highest impact in your area of service and industry.
        <br></br><br></br>
I conduct extensive market research and offer my strategic insights, ensuring your website will bring value to your target audience as well as help you compete shoulder to shoulder with your competitors.
<br></br><br></br>
I promote a collaborative approach to web design and consider your feedback at every stage, ensuring 100% satisfaction at the end of your design project.
        </Subtitle>
        
      </Wrapper></StyledSlide>

      <StyledSlide
        navDescription='Bogliasco - Italy'> <Wrapper>
          <img alt="" src='/images/responsive-web-design.svg' width="70" />
        <Title>
        
        Mobile Optimized
        </Title>
        <Subtitle>
        All my websites are designed to be mobile friendly.
        <br></br><br></br>
I take the time and effort in ensuring that every detail including font, image, video or functionality is consistent across mobile, tablet and desktop devices.
<br></br><br></br>
My mobile friendly web design solutions will ensure that you rank highly on Google giving your clients the best possible experience while browsing your site.
        </Subtitle>
        
      </Wrapper></StyledSlide>
      
      <StyledSlide
        navDescription='Bogliasco - Italy'> <Wrapper>
          <img alt="" src='/images/security.svg' width="70" />
        <Title>
        
        Security Optimized
        </Title>
        <Subtitle>
        I take extra care and precaution to ensure every website I design has maximum security against hackers and Brute Force attackers.
        <br></br><br></br>
        If you choose to go with my maintenance package, it means that you maintain me on a monthly basis to be your first point of contact against any security breaches, giving you that extra peice of mind.
        </Subtitle>
        
      </Wrapper></StyledSlide>


      <StyledSlide
        navDescription='Bogliasco - Italy'> <Wrapper>
          <img alt="" src='/images/cms.svg' width="70" />
        <Title>
        
        CMS
        </Title>
        <Subtitle>
        All our websites are built with a front end content management system (CMS) so that clients can maintain their website or online shop without the need to code.
        <br></br><br></br>
There are two options when it comes to CMS:
<br></br><br></br>
1- We build your site in it's entirety on WordPress.
<br></br><br></br>
2- We build your site from scratch using more robust frameworks such as React and Gatsby, then integrate WordPress or Contentful to your website.
        </Subtitle>
        
      </Wrapper></StyledSlide>


      <StyledSlide
        navDescription='Bogliasco - Italy'> <Wrapper>
          <img alt="" src='/images/smi.svg' width="70" />
        <Title>
        
        Social Media Integration
        </Title>
        <Subtitle>
     
        I use API's to integrate all your preferred social media platforms with your website, ensuring your most up to date content is available to your customers at all times, whatever the platform.
        <br></br><br></br>
New services or products added to your website will automatically and seamlessly be pushed to your social media platforms, so you can focus on the day to day running of your store or business.
        </Subtitle>
        
      </Wrapper></StyledSlide>
     
      
      
    </HeroSlider>
  )
}

export default app